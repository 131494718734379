$color: #000;
$background-color: #fff;
$primary-color: #ff9000;

.kiosk-qr-code-widget {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1rem;
  z-index: 90;

  &__button {
    background-color: rgba($color: $primary-color, $alpha: 0.7);
    border-radius: 10%;
    font-size: 2rem;
    cursor: pointer;
    color: #FFF;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $primary-color;
      transform: scale(1.05);
    }
  }

  &__container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50vh;
    width: 50vh;
    background-color: $background-color;
    border-radius: 5%;
    border: solid $primary-color 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 110;

    &--hidden {
      display: none;
    }
  }

  &__code {
    padding: 5% 5%;
    height: 100%;
  }

  .icon--large {
    max-width: 100px;
    max-height: 100px;
    width: 20vw;
    height: 20vw;
    color: $color;
  }
}
