@import 'mixins/layout_header';
@import 'breadcrumbs';

#layout_body {
  @include layout-header-margin($root: 'body');
  @include layout-header-margin($root: 'body.repainting_header_page', $extra-only: true)
}

#layout_body {
  section.content_center {
    @include grid-row;

    h1.title {
      @include grid-column(12);
    }
  }

  section.content_expand {
    @include grid-row;

    @include breakpoint(xlarge up) {
      @include grid-row-size(expand);
    }
  }
}
