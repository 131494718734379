.js-off-canvas-overlay {
  bottom: 0;
  right: 0;
}

.offcanvas_navigation {
  /// Off Canvas Main menu
  @import 'layout/offcanvas_navigation/main_menu';

  /// Off Canvas Social networks
  @import 'layout/offcanvas_navigation/social_networks';

  /// Off Canvas Social Sign In
  @import 'layout/offcanvas_navigation/sign_in';

  padding: rem-calc(90 50 50 50);

  &.is-transition-overlap {
    z-index: 10100;
  }

  .social_networks_and_sign_in {
    @include clearfix;
    padding-top: rem-calc(15);
  }
}

@include breakpoint(small only) {
  .offcanvas_navigation {
    width: rem-calc(270);
    padding: rem-calc(30 25);
  }
}

@include breakpoint(medium up) {
  .offcanvas_navigation {
    .social_networks_and_sign_in {
      padding-top: rem-calc(40);
    }
  }
}
