.subscribe-to-newsletter {
  &__success-message {
    display: none
  }

  &--success &__success-message {
    display: block;
  }

  &--success &__input-group {
    display: none;
  }

  &__input-group {
    @include xy-grid;
    flex-wrap: nowrap;
    position: relative;
    max-width: rem-calc(360);
  }

  &__input-field {
    @include xy-cell-base(auto);
    height: rem-calc(40);
    margin: 0;
    padding-right: rem-calc(15);
    padding-left: rem-calc(15);
    background: #f3f6fa;
    border-color: #f3f6fa;
    box-shadow: none;
    color: $black;
    line-height: 1;
    font: {
      size: rem-calc(16);
      weight: 300;
    }

    &::placeholder {
      color: #999;
    }
  }

  .button {
    @extend %with_cursor_without_outline;
    @include xy-grid;
    @include xy-cell-base;
    justify-content: center;
    align-items: center;
    height: rem-calc(40);
    width: rem-calc(40);
    margin: rem-calc(0 0 0 20);
    background: $orange;
    text-align: center;
    color: $white;
    font: {
      size: rem-calc(16);
    }

    &__text {
      display: none;
      visibility: hidden;
      color: inherit;
      font: {
        size: inherit;
      }
    }

    &__icon {
      width: rem-calc(20);
      height: rem-calc(20);
      fill: currentColor;
    }
  }
}

@include breakpoint(small only) {
  .subscribe-to-newsletter {
    .button {
      &__icon {
        // @include absolute-center;
      }
    }
  }
}

// TODO: Ann
// @include breakpoint(medium up) {
//   .subscribe-to-newsletter {
//     &__input-group {
//       width: rem-calc(614);
//     }

//     &__input-field {
//       padding-left: rem-calc(15);
//     }

//     .button {
//       width: rem-calc(166);
//       &__text {display: inline;}
//       &__icon {margin: rem-calc(-10 0 0 3);}
//     }
//   }
// }

// @include breakpoint(large up) {
//   .subscribe-to-newsletter {
//     &__input-group {
//       width: 100%;
//     }
//   }
// }
