.off-canvas-modal {
  overflow-y: auto;
  overflow-x: visible;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  color: $white;
  background-color: #000000AA;
  backdrop-filter: blur(10px);

  &.off-canvas {
    transform: translate(0, 0);
  }

  &.off-canvas.is-closed {
    transform: translate(-100vw, 0);
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &.is-animating {
    transition-property: transform, opacity, width;
    transition-timing-function: 0.5s ease 0.3s;
    visibility: visible;
  }
}

@include breakpoint(medium up) {
  .off-canvas-modal {
    justify-content: center;
    max-width: 50%;

    &.off-canvas.is-open {
      transform: translate(0, 0);
      width: 100%;
    }
  }
}
