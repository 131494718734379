/// Top Bar Logo
.top_bar {
  div.logo {
    // margin-top: rem-calc(-5);

    a.logo {
      display: block;
      height: rem-calc(30);
      width: rem-calc(90);

      background: {
        image: url('layout/logos/logo_black.svg');
        position: center center;
        repeat: no-repeat;
      }
    }
  }
}

@include breakpoint(medium up) {
  .top_bar {
    div.logo {
      margin-top: 0;

      a.logo {
        height: rem-calc(40);
        width: rem-calc(120);
      }
    }
  }
}
