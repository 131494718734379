.callout {
  @include callout-base;
  position: relative;
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 0;
  color: $white;
  background: $orange;

  h6 {
    margin: 0;
    font: {
      size: rem-calc(16);
    }
  }

  .close_button {
    @include universal_close_button($white, rem-calc(12));
    top: 0;
    right: 0;
  }

  &.success {
    color: $white;
    background: $orange;
  }

  &.danger.warning {
    color: $white;
    background: $black;
  }
}
