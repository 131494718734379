@mixin appear_animation($speed: 2s) {
  @keyframes appear_animation_fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  animation: appear_animation_fadein $speed;
}

@mixin soft_blink_animation($speed: 2s) {
  @keyframes soft_blink_animation_keyframes {
    0%   { opacity: 1; }
    25% { opacity: 0; }
    100%   { opacity: 1; }
  }

  animation: soft_blink_animation_keyframes 1s;
}

@mixin hover_scale_animation($scale: 1.05) {
  transition: transform .2s ease-out;

  &:hover {
    transform: scale($scale);
  }
}
