.layout_footer {
  .subscribe-to-newsletter {
    @include xy-grid;
    @include xy-cell-base;
    justify-content: center;

    &__input-field {
      height: rem-calc(48);
      border-radius: rem-calc(3 0 0 3);
    }

    .button {
      height: rem-calc(48);
      width: rem-calc(48);
      margin-left: 0;
      border-radius: rem-calc(0 3 3 0);
      text-transform: uppercase;
    }
  }
}

@include breakpoint(medium up) {
  .layout_footer {
    .subscribe-to-newsletter {
      justify-content: flex-start;

      &__input-group {
        min-width: rem-calc(440);
      }

      &__input-field {
        padding-left: rem-calc(15);
      }

      .button {
        width: rem-calc(166);

        &__text {
          display: block;
          visibility: visible;
          margin-right: rem-calc(15);
        }
      }
    }
  }
}

@include breakpoint(large up) {
  .layout_footer {
    .subscribe-to-newsletter {
      &__input-group {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

@include breakpoint(xlarge up) {
  .layout_footer {
    .newsletter {
      width: rem-calc(620);
    }
  }
}
