
.logo_and_link_to_rooms {
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    height: rem-calc(33);
    width: rem-calc(105);
    margin-bottom: rem-calc(15);
    background: {
      image: url('layout/logos/logo_white.svg');
      position: center center;
      repeat: no-repeat;
    }
  }

  .link_to_rooms {
    text-align: center;
    color: $white;
    line-height: 1;
    font: {
      size: rem-calc(14);
    }

    a {
      position: relative;
      display: inline-block;
      margin-top: rem-calc(10);
      padding-right: rem-calc(20);
      font: {
        weight: 700;
      }

      .icon {
        @include vertical-center;
        right: 0;
        height: rem-calc(14);
        width: rem-calc(14);
        margin-top: rem-calc(-1);
      }
    }
  }
}

@include breakpoint(medium up) {
  .logo_and_link_to_rooms {
    flex-direction: row;

    .logo {
      height: rem-calc(43);
      width: rem-calc(141);
      margin: rem-calc(-16 20 0 0);
    }

    .link_to_rooms {
      text-align: left;
      font: {
        size: rem-calc(16);
      }

      a {
        margin-top: rem-calc(5);
      }
    }
  }
}

@include breakpoint(large up) {
  .logo_and_link_to_rooms {
    .logo {
      height: rem-calc(50);
      width: rem-calc(161);
      margin-right: rem-calc(40);
    }
  }
}
