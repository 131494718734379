/// Off Canvas Main menu

.menu {
  @include menu-base;
  @include menu-direction(vertical);
  border-bottom: 1px solid #4e4b4b;

  a {
    overflow: hidden;
    position: relative;
    margin: rem-calc(20 0);
    padding: 0;
    color: #666;
    font: {
      size: rem-calc(24);
      weight: 300;
    }
    transition: transform 0.3s ease 0s;

    span {
      position: relative;
      display: block;
      transition: transform 0.3s ease 0s;

      &:before {
        content: attr(data-hover);
        position: absolute;
        top: 100%;
        transform: translate3d(0px, 0px, 0px);
        background-color: #1b1b1b;
      }

      &:after {
        content: attr(data-num);
        position: absolute;
        top: 100%;
        right: 0;
        transform: translate3d(0px, 0px, 0px);
        background-color: #1b1b1b;
        line-height: rem-calc(24);
        font: {
          size: rem-calc(13);
        }
      }
    }

    &:hover span,
    &:focus span {
      color: #fff;
      transform: translateY(-100%);
    }
  }
}

@include breakpoint(small only) {
  .close-button {
    span {
      display: none;
    }
  }

  .menu {
    padding-bottom: rem-calc(10);

    a {
      margin: rem-calc(12 0);
      padding: 0;
      font: {
        size: rem-calc(16);
      }

      span {
        &:after {
          line-height: rem-calc(16);
          font: {
            size: rem-calc(12);
          }
        }
      }
    }
  }
}
