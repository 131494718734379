@mixin universal_close_button(
                    $color: $black,
                    $size: rem-calc(16),
                    $width: rem-calc(38),
                    ) {
  @include close-button;
  height: $width;
  width: $width;
  @extend %with_cursor_without_outline;

  &::before {
    @include absolute-center;
    content: '';
    height: $size;
    width: $size;
    background-color: $color;
    mask: url('data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMjIgMzIiPgo8dGl0bGU+dGltZXM8L3RpdGxlPgo8cGF0aCBkPSJNMTUuMTcgMTZsNi4yNTQgNi4yNTRjMC43NjggMC43NjggMC43NjggMi4wMTIgMCAyLjc4bC0xLjM5IDEuMzljLTAuNzY4IDAuNzY4LTIuMDEyIDAuNzY4LTIuNzggMGwtNi4yNTQtNi4yNTQtNi4yNTQgNi4yNTRjLTAuNzY3IDAuNzY4LTIuMDEyIDAuNzY4LTIuNzggMGwtMS4zOS0xLjM5Yy0wLjc2OC0wLjc2OC0wLjc2OC0yLjAxMiAwLTIuNzhsNi4yNTQtNi4yNTQtNi4yNTQtNi4yNTRjLTAuNzY4LTAuNzY4LTAuNzY4LTIuMDEyIDAtMi43OGwxLjM5LTEuMzljMC43NjgtMC43NjggMi4wMTMtMC43NjggMi43OCAwbDYuMjU0IDYuMjU0IDYuMjU0LTYuMjU0YzAuNzY4LTAuNzY4IDIuMDEyLTAuNzY4IDIuNzggMGwxLjM5IDEuMzljMC43NjggMC43NjggMC43NjggMi4wMTMgMCAyLjc4eiI+PC9wYXRoPgo8L3N2Zz4K') no-repeat center / cover;
    transition: background-color 0.25s ease-in-out;
  }

  &:hover {
    &::before {
      background-color: scale-color($color, $lightness: -15%);
    }
  }
}

@mixin universal_close_button_with_svg(
                    $color: $black,
                    $size: rem-calc(16),
                    $height: rem-calc(38),
                    ) {
  @include close-button;
  height: $height;
  width: $height;
  color: $color;
  font: {
    size: $size;
  }
  transition: color 0.25s ease-in-out;
  @extend %with_cursor_without_outline;

  .icon {
    @include absolute-center;
    height: calc( $height / 2.5);
    width: calc( $height / 2.5);
    fill: $color;
  }

  &:focus {
    color: $color;
  }

  &:hover {
    color: scale-color($color, $lightness: -15%);

    .icon {
      fill: scale-color($color, $lightness: -15%);
    }
  }

  @include breakpoint(small only) {
    .icon {
      height: calc( $height / 3);
      width: calc( $height / 3);
    }
  }
}
