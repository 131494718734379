.temporary_closed {
  height: rem-calc(65);
  background: #d20002;
  box-shadow: 0 0 5px 0 rgba($black, 0.2);
  color: $white;
  line-height: 1.2;
  position: fixed;
  z-index: 10;
  width: 100%;

  &.slide_up {
    transition: transform 0.3s ease 0s;
    transform: translateY(rem-calc(-65));
  }

  .content_container {
    @include xy-grid-container(rem-calc(1820), 0);
    position: relative;
    height: 100%;
  }

  .attention_section,
  .text_section {
    @include xy-gutters($grid-margin-gutters, padding);
    height: 100%;
    align-items: center;
    display: flex;
  }

  .text_section > * {
    display: block;
  }

  .attention_section {
    display: none;
    visibility: hidden;
    background: #c30002;
  }

  .attention_label,
  .label {
    font: {
      weight: 700;
    }
    text-transform: uppercase;
  }

  .attention_label {
    .icon {
      display: inline-block;
      height: rem-calc(18);
      width: rem-calc(21);
      margin: rem-calc(0 5 -2 0);
    }
  }

  button {
    color: inherit;
  }

  .close_button,
  .read_more {
    @extend %with_cursor_without_outline;
  }

  .close_button {
    position: absolute;
    top: rem-calc(3);
    right: rem-calc(5);
    width: rem-calc(24);
    height: rem-calc(24);

    .icon {
      height: rem-calc(16);
      width: rem-calc(16);
      fill: currentColor;
    }
  }

  .text_section {
    width: 100%;
    padding-top: rem-calc(8);
    padding-bottom: rem-calc(5);
    background: #d20002;
    text-align: center;

    font: {
      size: rem-calc(14);
    }
  }

  .label {
    display: block;
    margin-bottom: rem-calc(3);
    line-height: 1;
  }

  .read_more {
    margin-left: rem-calc(5);
    padding-bottom: rem-calc(1);
    border-bottom: 1px solid $white;
  }
}

@include breakpoint(520px up) {
  .temporary_closed {
    .content_container {
      @include xy-grid;
    }

    .attention_section {
      @include xy-cell-base(shrink);
      @include xy-grid;
      align-items: center;
      display: flex;
      visibility: visible;
    }

    .text_section {
      @include xy-cell-base(auto);
      padding-right: rem-calc(28);
      text-align: left;
    }

    .label {
      display: inline-block;
      margin-bottom: 0;
      margin-right: rem-calc(5);
      line-height: inherit;
    }
  }
}

@include breakpoint(medium up) {
  .temporary_closed {
    background: linear-gradient(to right, #c30002, #d20002);

    .close_button {
      top: rem-calc(10);
      bottom: rem-calc(10);
    }

    .attention_label {
      font-size: rem-calc(18);
    }

    .text_section {
      font: {
        size: rem-calc(16);
      }
    }
  }
}

@include breakpoint(large up) {
  .temporary_closed {
    .attention_label {
      .icon {
        margin-right: rem-calc(15);
      }
    }
  }
}

@include breakpoint(xxlarge up) {
  .temporary_closed {
    .text_section {
      cursor: default;
    }

    .read_more {
      display: none;
      visibility: hidden;
    }
  }
}

@media screen and (min-width: 90em) and (max-width: 119.938em) {
  .temporary_closed {
    .text_section {
      font: {
        size: rem-calc(14);
      }
    }
  }
}

@include breakpoint(1920px up) {
  .temporary_closed {
    .attention_section {
      width: rem-calc(235);
    }

    .attention_label {
      font: {
        size: rem-calc(22);
      }

      .icon {
        height: rem-calc(22);
        width: rem-calc(25);
      }
    }
  }
}
