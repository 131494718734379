.hhla-opening-modal {
  .off-canvas-modal__body {
    justify-content: flex-start;
  }

  max-width: 400px;

  .close_button {
    top: rem-calc(20);
    right: rem-calc(20);
    text-shadow: 0 0px 5px #0000
  }
}

.hhla-opening {
  &__image-container {
    display: block;
    // margin-bottom: rem-calc(20);

    img {
      width: 100%;
    }
  }

  &__content-container {
    @include xy-gutters($grid-margin-gutters, padding);
    padding-bottom: rem-calc(30);
  }

  &__title {
    font-size: rem-calc(28);
  }

  &__subscribe {
    button {
      margin-left: 0 !important;
      border-radius: 0 5px 5px 0 !important;
    }

    .subscribe-to-newsletter__input-field {
      border-radius: 5px 0 0 5px !important;
    }
  }
}
