$base-header-height: (
  small: rem-calc(61),
  medium: rem-calc(65),
) !default;

$extra-header-height: (
  small: rem-calc(65),
  medium: rem-calc(65),
) !default;

@mixin layout-header-margin($root: 'body', $extra-only: false) {
  &.is_animating {
    transition: margin-top 0.3s ease 0s;
  }

  @each $name, $height in $base-header-height {
    @if $extra-only {
      $height: 0;
    }

    @include breakpoint($name up) {
      @at-root #{$root} & {
        margin-top: $height;
      }

      @at-root #{selector-unify("body.extended_header", $root)} & {
        margin-top: $height + map-get($extra-header-height, $name);
      }
    }
  }
}
