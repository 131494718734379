/// Off Canvas Social networks

.social_networks {
  @include clearfix;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    float: left;
    color: #4e4b4b;

    &.title {
      margin-right: rem-calc(15);
      padding-top: rem-calc(2);
      font: {
        size: rem-calc(14);
      }
      text-transform: uppercase;
    }

    &.icon {
      a {
        position: relative;
        display: block;
        height: rem-calc(22);
        width: rem-calc(33);
      }
    }

    .svg_icon {
      @include absolute-center;
      height: rem-calc(20);
      width: rem-calc(20);
      fill: #4e4b4b;
    }
  }
}

@include breakpoint(small only) {
  .social_networks {
    margin-bottom: rem-calc(18);

    li {
      &.title {
        line-height: rem-calc(22);
        font: {
          size: rem-calc(13);
        }
      }

      .svg_icon {
        height: rem-calc(18);
        width: rem-calc(18);
      }
    }
  }
}

@include breakpoint(medium up) {
  .social_networks {
    float: left;

    li {

      &.title {
        margin-right: rem-calc(15);
      }

      &.icon {
        a {
          width: rem-calc(30);
        }
      }
    }
  }
}
