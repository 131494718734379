/// Прибиваем футер к низу экрана
@mixin sticky-footer {
  .off-canvas-wrapper {
    overflow: visible;
    width: 100%;
  }

  .off-canvas-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
  }

  header,
  footer {
    flex-shrink: 0;
  }

  main {
    flex: 1 0 auto; /* Use 1 0 auto instead of just 1 for backwards compatibility with other browsers */
  }
}

