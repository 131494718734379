@charset 'utf-8';

@import 'settings.scss';
@import 'foundation.scss';
// @import '~motion-ui/src/motion-ui.scss';

@include foundation-typography;
@include foundation-forms;
@include foundation-close-button;
@include foundation-dropdown;
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-visibility-classes;

/// Mixins
@import
  'mixins/decorate_elements',
  'mixins/buttons',
  'mixins/close_button',
  'mixins/form_components/select',
  'mixins/form_components/input_float_label',
  'mixins/highlight_animations',
  'mixins/sticky_footer';

/// Layout
@import 'layout';

/// Components
@import 'components/disable_animations_unless_loaded';
@import 'components/cookies_acceptance';
@import 'components/modal_alert';
@import 'components/teaser';

// FIXME: Аня, куда это лучше разместить?
svg.icon { fill: currentColor }

.reveal,
.reveal:focus {
  outline: none;
}

.reveal-overlay {
  background-color: rgba(10, 10, 10, 0.9);
  z-index: 10010;
}

// disabled links
a.disabled {
  cursor: default;
}

.form-error {
  margin: rem-calc(5 0 0 0);
}

#checkpoint_el {
  display: none !important;
}
