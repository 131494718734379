
.social_networks {
  @include xy-grid;
  flex: 0 0 auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: rem-calc(0 0 30);
  padding: 0;
  list-style: none;

  li {
    flex: 0 0 auto;
    color: $white;
    line-height: 1;
  }

  .title {
    margin-right: rem-calc(10);
    padding-top: rem-calc(2);
    font: {
      size: rem-calc(14);
    }
  }

  .icon {
    text-align: center;

    a {
      position: relative;
      display: block;
      height: rem-calc(48);
      width: rem-calc(48);
      padding: rem-calc(13);
      background-clip: content-box;
      border-radius: 50%;
    }
  }

  .svg_icon {
    @include absolute-center;
    height: rem-calc(12);
    width: rem-calc(12);
    fill: $white;
  }

  .instagram {
    background: #d8297f;
  }

  .facebook {
    background: #385291;
  }

  .twitter {
    background: #00b5f4;
  }

  .tiktok {
    background: #fc024f;
  }
}

@include breakpoint(medium only) {
  .social_networks {
    position: absolute;
    bottom: rem-calc(-68);
    left: rem-calc(15);
    width: auto;
  }
}

@include breakpoint(medium up) {
  .social_networks {
    justify-content: flex-start;
    margin-bottom: 0;

    .title {
      font: {
        size: rem-calc(16);
      }
    }

    .icon {
      a {
        padding: rem-calc(10);
      }
    }

    .svg_icon {
      height: rem-calc(15);
      width: rem-calc(15);
    }
  }
}

@include breakpoint(large up) {
  .social_networks {
    align-content: flex-start;
    align-items: flex-start;
    width: 24.999%;
    margin-bottom: 0;

    .title {
      width: 100%;
      margin: rem-calc(0 0 13 10);
    }
  }
}
