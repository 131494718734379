.off-canvas {
  .close_button {
    @include universal_close_button_with_svg($white);
    top: rem-calc(10);
    right: rem-calc(10);
    font: {
      size: rem-calc(16);
    }
    text-transform: uppercase;
  }
}

@include breakpoint(small only) {
  .off-canvas {
    .close_button {
      .text {
        display: none;
        visibility: hidden;
      }
    }
  }
}

@include breakpoint(medium up) {
  .off-canvas {
    .close_button {
      top: rem-calc(40);
      right: rem-calc(35);
      width: auto;
      padding-right: rem-calc(25);

      .icon {
        right: 0;
        left: auto;
        transform: translate(0, -50%);
        margin-top: rem-calc(-2);
      }
    }
  }
}
