/// Top Bar Pages navigation (rooms, location, schedule)
.top_bar {
  ul.pages_nav {
    @include menu-base;
    float: right;
    padding: rem-calc(0 5) !important;

    a {
      display: block;
      padding: rem-calc(14 20 12 20);
      color: #000;
      line-height: 1;
      font: {
        size: rem-calc(14);
      }
      text-transform: uppercase;
    }

    @include breakpoint(medium only) {
      a {
        padding-left: rem-calc(18);
        padding-right: rem-calc(18);
      }
    }

    @include breakpoint(xxlarge up) {
      padding: 0 !important;
    }

    @include breakpoint(1920px up) {
      padding: rem-calc(0 40 0 0) !important;
    }
  }
}
