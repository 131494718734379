/// Charity Cancer

.charity_cancer_container {
  width: 100%;

  @include breakpoint(medium up) {
    width: auto;
  }
}

.charity_cancer {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding-top: rem-calc(17);

  .logo {
    flex: 0 0 auto;
    position: relative;
    height: rem-calc(12);
    width: rem-calc(115);
    margin-right: rem-calc(10);
    * { display: block; }
  }

  .counter {
    flex: 0 0 auto;
    position: relative;
    height: rem-calc(21);
    margin-right: rem-calc(70);
    padding: rem-calc(6 15 4 12);
    background: #d2b169;
    border-radius: rem-calc(5);
    line-height: 1;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      height: 0;
      width: 0;
      margin-top: rem-calc(-4);
      border: solid transparent;
      border-color: rgba(210, 177, 105, 0);
      border-right-color: #d2b169;
      border-width: rem-calc(4);
      pointer-events: none;
    }

    .value {
      color: #000;
      font: {
        size: rem-calc(12);
      }
      white-space: nowrap;
    }
  }

  .link_to_donate {
    position: absolute;
    top: 50%;
    right: rem-calc(-70);
    transform: translateY(-50%);
    padding: rem-calc(1 0 0 20);
    font: {
      size: rem-calc(13);
    }
    text-transform: uppercase;

    .icon {
      @include vertical-center;;
      left: rem-calc(4);
      height: rem-calc(14);
      width: rem-calc(14);
    }
  }

  .link_to_letter {
    display: none;
    position: absolute;
    bottom: rem-calc(-21);
    right: 0;
  }

  @include breakpoint(medium up) {
    float: left;
    justify-content: flex-start;
    padding-top: 0;
    margin: 0;

    .logo {
      height: rem-calc(15);
      width: rem-calc(139);
      margin-right: rem-calc(14);
    }

    .counter {
      height: rem-calc(27);
      padding: rem-calc(9 25 4 20);

      &:before {
        margin-top: rem-calc(-6);
        border-width: rem-calc(6);
      }

      .value {
        font: {
          size: rem-calc(14);
        }
      }
    }
  }
}
