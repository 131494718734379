/// Top Bar TripAdvisor
.top_bar {
  .tripadvisor {
    margin: rem-calc(2 0 0 10);

    svg {
      display: block;
      height: rem-calc(27);
      width: rem-calc(97);
      background-size: cover;
    }

    @include breakpoint(medium up) {
      svg {
        height: rem-calc(34);
        width: rem-calc(120);
      }
    }

    @include breakpoint(large up) {
      margin-left: rem-calc(15);

      svg {
        height: rem-calc(37);
        width: rem-calc(135);
      }
    }
  }
}
