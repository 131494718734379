.teaser {
  display: block;
  position: fixed;
  bottom: rem-calc(20);
  left: -100%;
  z-index: 10000;
  min-height: rem-calc(50);
  width: rem-calc(250);
  padding: rem-calc(10 30 10 15);
  background: #fff;
  border-radius: rem-calc(5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  &.teaser_hide {
    opacity: 0;
    transition: all 1s linear;
  }

  &.teaser_show {
    left: rem-calc(20);
    opacity: 1;
    transition: all 1s linear;
  }

  .container {
    display: flex;
    align-items: center;
    line-height: 1.3;
    color: black;

    font: {
      size: rem-calc(12);
    }

    .subject {
      color: #ff9000;
      font-weight: bold;
    }
  }

  .close_button {
    @include universal_close_button(#8a8a8a, rem-calc(10), rem-calc(30));
    top: 0;
    right: 0;
  }
}
