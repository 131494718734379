/// Top Bar Layout
.top_bar {
  @include xy-grid-container(rem-calc(1920));
  @include xy-grid;
  justify-content: space-between;
  padding: rem-calc(10 0 10 15);
  min-height: rem-calc(35);

  .left_section,
  .right_section {
    @include xy-grid;
  }

  .left_section {
    align-items: center;
  }

  .right_section {
    justify-content: flex-end;
  }

  .pages_nav {
    @include element-invisible;
  }

  .sign_in_place {
    @include xy-grid;
    justify-content: flex-end;
    align-items: stretch;
  }

  .sign_in {
    @include element-invisible;
  }
}

@include breakpoint(medium up) {
  .top_bar {
    padding-right: 0;

    .left_section {
      @include xy-cell(shrink, false);
    }

    .right_section {
      @include xy-cell(auto, false);
    }

    .pages_nav {
      @include element-invisible-off;
    }

    .sign_in_place {
      min-width: rem-calc(76);
    }

    .sign_in {
      @include element-invisible-off;
    }
  }
}

@include breakpoint(xlarge up) {
  .top_bar {
    padding-right: rem-calc(15);
  }
}

@include breakpoint(xxlarge up) {
  .top_bar {
    padding-right: rem-calc(25);
  }
}

@include breakpoint(1920px up) {
  .top_bar {
    @include grid-column-gutter(auto, 140px);
  }
}
