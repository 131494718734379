/// Off Canvas Social Sign In

.social_networks_and_sign_in {
  div.sign_in {

    a.signin_button,
    a.customer_name {
      position: relative;
      padding-right: rem-calc(25);
      color: #ff9000;
      font: {
        size: rem-calc(14);
      }

      &::before {
        @include vertical-center;
        content: '';
        right: 0;
        height: rem-calc(17);
        width: rem-calc(15);
        margin-top: rem-calc(-2);
        background-color: $orange;
        mask: url('data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjI4IiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMjggMzIiPgo8dGl0bGU+dXNlci1yPC90aXRsZT4KPHBhdGggZD0iTTE5LjYgMTljNC42MzcgMCA4LjQgMy43NjIgOC40IDguNHYxLjZjMCAxLjY1Ni0xLjM0NCAzLTMgM2gtMjJjLTEuNjU2IDAtMy0xLjM0NC0zLTN2LTEuNmMwLTQuNjM3IDMuNzYzLTguNCA4LjQtOC40IDEuOCAwIDIuNjU2IDEgNS42IDFzMy44MDYtMSA1LjYtMXpNMjUgMjl2LTEuNmMwLTIuOTc1LTIuNDI1LTUuNC01LjQtNS40LTAuOTE5IDAtMi4zNjkgMS01LjYgMS0zLjIwNiAwLTQuNjg4LTEtNS42LTEtMi45NzUgMC01LjQgMi40MjUtNS40IDUuNHYxLjZoMjJ6TTE0IDE4Yy00Ljk2OSAwLTktNC4wMzEtOS05czQuMDMxLTkgOS05IDkgNC4wMzEgOSA5LTQuMDMxIDktOSA5ek0xNCAzYy0zLjMwNiAwLTYgMi42OTQtNiA2czIuNjk0IDYgNiA2IDYtMi42OTQgNi02LTIuNjk0LTYtNi02eiI+PC9wYXRoPgo8L3N2Zz4K') no-repeat center / cover;
      }
    }

    @include breakpoint(small only) {
      a.signin_button,
      a.customer_name {
        &::before {
          height: rem-calc(16);
          width: rem-calc(14);
        }
      }
    }

    @include breakpoint(medium up) {
      float: right;
    }
  }
}
