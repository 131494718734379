.no-interaction * {
  transition-property: none !important;
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;

  animation: none !important;
  -o-animation: none !important;
  -moz-animation: none !important;
  -ms-animation: none !important;
  -webkit-animation: none !important;
}
