/// Top Bar Cart

@keyframes top_bar_shopping_cart_changed {
  from {
    transform: scale(10);
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.shopping_cart_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  min-width: rem-calc(40);
  min-height: rem-calc(37);

  &.has_items {
    .shopping_cart_link {
      width: 100%;
      height: auto;
      display: flex;
    }
  }

  .shopping_cart_link {
    display: none;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 0;
    height: 0;
    color: $black;
    line-height: 1;
    outline: none;

    .icon {
      height: rem-calc(23);
      width: rem-calc(23);
      fill: $black;

      @at-root .repainting_header_page & {
        fill: $white;
      }

      @at-root .repainting_header_page.header_repainted & {
        fill: $black;
      }
    }

    @include hover_scale_animation(1.2);
  }

  &.has_items {
    .shopping_cart_link {
      opacity: 1;
    }
  }

  .items_counter {
    position: absolute;
    bottom: rem-calc(1);
    right: rem-calc(4);
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem-calc(15);
    width: rem-calc(15);
    background: #ff9000;
    border-radius: 50%;
    text-align: center;
    line-height: 1;

    font: {
      size: rem-calc(12);
      weight: 300;
    }
  }

  &.changed {
    transition: width 0.3s ease-in-out 0s, margin-left 0.3s ease-in-out 0s;

    .shopping_cart_link .items_counter {
      animation: top_bar_shopping_cart_changed 0.4s ease-in;
    }
  }
}
