.cookies-acceptance-message {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  transform: translateY(0);
  backface-visibility: hidden;

  &.is_animating {
    transition-property: transform, opacity;
    transition: transform 0.3s ease 0s;
  }

  &__control-button {
    @extend %with_cursor_without_outline;
    position: relative;
    display: inline-block;
    width: rem-calc(112);
    margin-left: rem-calc(15);
    padding: rem-calc(10 20 7 6);
    background: #004db6;
    border-radius: rem-calc(5 5 0 0);
    text-align: left;
    color: $white;
    line-height: 1;

    font: {
      size: rem-calc(12);
    }

    text-transform: uppercase;

    .icon {
      @include vertical-center;
      right: rem-calc(7);
      height: rem-calc(12);
      width: rem-calc(12);
      transition: opacity 0.3s ease-in-out;
    }

    .caret_down {
      opacity: 1;
    }

    .caret_up {
      opacity: 0;
    }
  }

  &__content-container {
    height: rem-calc(72);
    color: $white;

    font: {
      size: rem-calc(14);
    }
  }

  &__inner-container {
    @include xy-gutters($grid-margin-gutters, padding);
    position: relative;
    height: 100%;
    max-width: rem-calc(1280);
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(15);

    button {
      @include white_border_button;
      margin-left: rem-calc(10);
    }

    a {
      color: $white;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &.collapsed {
    transform: translateY(70px);

    .control_button {
      .caret_down {
        opacity: 0;
      }

      .caret_up {
        opacity: 1;
      }
    }
  }
}

@include breakpoint(small only) {
  .cookies-acceptance-message {
    &__content-container {
      height: rem-calc(160);
    }

    &__inner-container {
      button {
        padding-top: rem-calc(10);
      }
    }

    &.collapsed {
      transform: translateY(189px);
    }
  }
}

@include breakpoint(medium up) {
  .cookies-acceptance-message {
    &__control-button {
      width: rem-calc(148);
      margin-left: rem-calc(15);
      padding: rem-calc(13 35 9 12);

      font: {
        size: rem-calc(14);
      }

      .icon {
        right: rem-calc(18);
      }
    }

    &__content-container {
      height: rem-calc(115);

      font: {
        size: rem-calc(16);
      }
    }

    &__inner-container {
      button {
        padding-top: rem-calc(12);
        padding-bottom: rem-calc(12);

        font: {
          size: rem-calc(14);
        }
      }
    }

    &.collapsed {
      transform: translateY(151px);
    }
  }
}

@include breakpoint(large up) {
  .cookies-acceptance-message {
    &__content-container {
      height: rem-calc(95);
    }

    &.collapsed {
      transform: translateY(131px);
    }
  }
}

@include breakpoint(xlarge up) {
  .cookies-acceptance-message {
    &__control-button {
      margin-left: rem-calc(25);
    }
  }
}

@include breakpoint(1920px up) {
  .cookies-acceptance-message {
    &__control-button {
      margin-left: rem-calc(70);
    }
  }
}

.cookies-acceptance-message {

  &__content-container {
    position: relative;
    background: linear-gradient(to right, rgba(0, 77, 182, 1) 0%, rgba(255, 157, 0, 1) 100%);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding-top: rem-calc(2);

      background: {
        position: center 0;
        repeat: no-repeat;
      }

      background-size: cover;
      background-clip: content-box;

      @at-root .no-webp-lossy & {
        background-image: url('pages/home_page/promotions_banner/gift_cards_available/bg_sm.png');
      }

      @at-root .webp-lossy & {
        background-image: url('pages/home_page/promotions_banner/gift_cards_available/bg_sm.webp');
      }
    }
  }

  .inner_container {
    @include xy-grid;
    position: relative;
  }
}

@include breakpoint(medium up) {
  .cookies-acceptance-message {
    &__content-container {
      &::before {
        @at-root .no-webp-lossy & {
          background-image: url('pages/home_page/promotions_banner/gift_cards_available/bg_md.png');
        }

        @at-root .webp-lossy & {
          background-image: url('pages/home_page/promotions_banner/gift_cards_available/bg_md.webp');
        }
      }
    }
  }
}

@include breakpoint(large up) {
  .cookies-acceptance-message {
    &__control-button {
      background: #c818a9;
    }

    &__content-container {
      background: linear-gradient(to right, rgba(200, 24, 169, 1) 0%, rgba(255, 157, 0, 1) 100%);

      &::before {
        @at-root .no-webp-lossy & {
          background-image: url('pages/home_page/promotions_banner/gift_cards_available/bg_md.png');
        }

        @at-root .webp-lossy & {
          background-image: url('pages/home_page/promotions_banner/gift_cards_available/bg_md.webp');
        }
      }
    }
  }
}
