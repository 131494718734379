/// Footer layout

.layout_footer {
  width: 100%;
  min-width: rem-calc(320);
  padding-top: rem-calc(25);
  background: #1b1b1b;
  color: #565656;

  .content_container {
    @include xy-grid-container;
    @include xy-grid;

    &:nth-child(3) {
      padding-top: rem-calc(15);
      padding-bottom: rem-calc(15);
      border-top: 1px solid rgba(78, 75, 75, 0.46);
    }
  }

  .logo_and_link_to_rooms,
  .newsletter {
    @include xy-cell-base;
    width: 100%;
  }

  .logo_and_link_to_rooms {
    margin-bottom: rem-calc(25);
  }

  .newsletter {
    margin-bottom: rem-calc(30);
    padding: rem-calc(0 15);
  }

  ul {
    margin: 0;
    padding: 0;
  }
}

@include breakpoint(medium only) {
  .layout_footer {
    .content_container {
      &:nth-child(2) {
        position: relative;
      }
    }
  }
}

@include breakpoint(medium up) {
  .layout_footer {
    padding-top: rem-calc(40);

    .content_container {
      &:nth-child(3) {
        justify-content: flex-end;
        align-items: center;
        padding-top: rem-calc(30);
        padding-bottom: rem-calc(30);
      }
    }

    .logo_and_link_to_rooms {
      margin-bottom: rem-calc(35);
    }

    .newsletter {
      margin-bottom: rem-calc(50);
      padding: 0;
    }
  }
}

@include breakpoint(large up) {
  .layout_footer {
    .content_container {
      &:nth-child(1) {
        justify-content: space-between;
        margin-bottom: rem-calc(50);
      }

      &:nth-child(2) {
        margin-bottom: rem-calc(20);
      }

      &:nth-child(3) {
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }

    .logo_and_link_to_rooms,
    .newsletter {
      margin-bottom: 0;
    }

    .logo_and_link_to_rooms {
      margin-right: rem-calc(50);
      width: auto;
    }

    .newsletter {
      width: rem-calc(500);
    }
  }
}
