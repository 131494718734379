@mixin modal_close_button {
  .close-button {
    top: 0;
    right: 0;
    padding: rem-calc(15);
    color: #ff4056;
    font-size: rem-calc(13);
    text-transform: uppercase;
    outline: none;

    span {
      position: relative;
      display: block;
      padding-right: rem-calc(25);

      .icon {
        width: rem-calc(18);
        height: rem-calc(18);
        fill: currentColor;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-62%);
      }
    }
  }
}

@mixin modal {
  @include modal_close_button;
  overflow-y: visible;
  background: none;
  border: 0;
  color: $white;
  text-align: center;

  .container {
    margin: rem-calc(26 0);
  }

  .buttons_place {
    margin-top: rem-calc(26);

    button {
      font-size: rem-calc(18);
      color: $orange;
    }
  }

  h1 {
    margin-bottom: rem-calc(20);
    text-align: center;

    .location-name {
      color: #ff9000;
    }
  }

  @include breakpoint(xlarge up) {
    h1 {
      margin-bottom: rem-calc(40);
    }
  }

  // .loading {
  //   @include react_loading;

  //   .spinner {
  //     border-top-color: #fff;
  //     border-bottom-color: #fff;
  //   }
  // }
}

@mixin action_modal {
  @include modal_close_button;
  max-width: rem-calc(420);
  padding: rem-calc(25 10);
  background: transparent;
  border: 0;

  .close-button {
    top: rem-calc(5);
    margin: 0;
  }

  .container {
    padding: rem-calc(25 15 15);
    background: #fefefe;
    border-radius: rem-calc(20);
    text-align: center;
  }

  .body {
    font: {
      size: rem-calc(16);
    }
  }

  h1.title {
    margin-bottom: rem-calc(5);
    font: {
      size: rem-calc(16);
      weight: 400;
    }
    text-transform: uppercase;
  }

  p {
    margin-bottom: rem-calc(20);
  }

  .buttons_place {
    button {
      display: inline-block;
      padding: rem-calc(10);
      color: #ff9000;
      cursor: pointer;
      outline: none;
    }
  }
}
