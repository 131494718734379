.navigations {
  flex: 0 0 auto;
  width: 100%;

  .navigation {
    margin-bottom: rem-calc(20);
  }

  .title {
    margin-bottom: rem-calc(10);
    text-align: center;
    color: $white;
    line-height: 1;
    font: {
      size: rem-calc(14);
      weight: 400;
    }
  }

  ul {
    column-count: 2;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1;
    font: {
      size: rem-calc(14);
    }

    li {
      page-break-inside: avoid;
      break-inside: avoid;
      margin-bottom: rem-calc(1);
    }

    a {
      display: block;
      padding: rem-calc(17 15);
      color: #999;
      white-space: nowrap;

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }
}

@include breakpoint(medium up) {
  .navigations {
    display: flex;

    .navigation {
      flex: 0 0 auto;
      width: 50%;

      &.locations_nav {
        padding-left: rem-calc(30);
      }
    }

    .title {
      text-align: left;
      font: {
        size: rem-calc(16);
      }
    }

    ul {
      a {
        position: relative;

        &::before {
          @include vertical-center;
          content: '';
          left: 0;
          height: rem-calc(4);
          width: rem-calc(4);
          margin-top: rem-calc(-1);
          background: #999;
        }
      }
    }
  }
}

@include breakpoint(large up) {
  .navigations {
    width: 74.997%;

    .navigation {
      margin-bottom: 0;
    }

    .title {
      margin-bottom: rem-calc(20);
    }

    ul {
      a {
        padding-top: rem-calc(12);
        padding-bottom: rem-calc(12);
      }
    }
  }
}
