/// Buttons
@mixin base_button {
  cursor: pointer;
  outline: none;

  &[disabled],
  &.disabled {
    background-color: #c2c2c2;
    border-color: #a7a7a7;
    color: $black;
    opacity: 0.25;
    cursor: not-allowed;

    &:hover,
    &:focus {
      background-color: #c2c2c2;
      border-color: #a7a7a7;
      color: inherit;
      transform: none;
    }
  }
}

@mixin rounded_button($background, $background-hover, $color, $color-hover: $color, $border: transperent, $border-hover: $border) {
  @include base_button;
  @include button(false, $background, $background-hover, $color, solid);
  margin: 0;
  padding: rem-calc(12 20 9 20);

  font: {
    size: rem-calc(12);
  }

  border: {
    color: $border;
  }

  @include border-rounded;
  transition: background-color 0.25s ease-out,
  color 0.25s ease-out,
  transform 0.25s ease-out;

  &:hover,
  &:focus {
    color: $color-hover;
    transform: scale(1.05);

    border: {
      color: $border-hover;
    }
  }
}


@mixin orange_border_button {
  @include base_button;
  @include button(false, transparent, $orange, $orange);
  margin: 0;
  padding: rem-calc(12 20 9 20);
  border: 1px solid $orange;
  @include border-rounded;

  font: {
    size: rem-calc(12);
  }

  &:hover,
  &:focus {
    color: #000;
  }

  @include breakpoint(medium up) {
    padding: rem-calc(15 25 11 25);

    font: {
      size: rem-calc(20);
    }
  }
}


@mixin white_border_button {
  @include base_button;
  @include button(false, transparent, #fff, #fff);
  margin: 0;
  padding: rem-calc(12 20 9 20);
  border: 1px solid #fff;
  @include border-rounded;

  font: {
    size: rem-calc(12);
  }

  &:hover,
  &:focus {
    color: #000;
  }

  @include breakpoint(medium up) {
    padding: rem-calc(15 25 11 25);

    font: {
      size: rem-calc(20);
    }
  }
}

@mixin orange_background_button {
  @include base_button;
  @include button(false, #ff9000, #ff9e20, #000);
  margin: 0;
  padding: rem-calc(12 20 9 20);
  border: 1px solid #ff9000;
  @include border-rounded;

  font: {
    size: rem-calc(12);
  }

  transition: background-color 0.25s ease-out,
  color 0.25s ease-out,
  transform 0.25s ease-out;

  &:hover,
  &:focus {
    color: #000;
    transform: scale(1.05);
  }

  // For buttons that have spinner
  &.working {
    color: transparent;

    &:hover,
    &:focus {
      color: transparent;
    }
  }

  .load_spinner {
    left: 50%;
    width: rem-calc(25);
    height: rem-calc(25);
    margin-left: rem-calc(-12.5);
    margin-top: rem-calc(-5);
  }

  @include breakpoint(medium up) {
    padding: rem-calc(15 25 11 25);

    font: {
      size: rem-calc(20);
    }
  }
}

.orange_button {
  @include orange_background_button;
}

@mixin white_background_button {
  @include base_button;
  @include button(false, $white, #bdbdbd, $black);
  @include border-rounded;
}

@mixin gray_background_button {
  @include base_button;
  @include button(false, #f7f6f6, #bdbdbd, #d5d5d5);
  margin: 0;
  border-radius: rem-calc(5);

  font: {
    size: rem-calc(13);
  }

  text-transform: uppercase;
  transition: background-color 0.25s ease-out,
  color 0.25s ease-out,
  transform 0.25s ease-out;

  &:hover,
  &:focus,
  &.active {
    background-color: #bdbdbd;
    color: #fff;
    transform: scale(1.05);
  }
}

.gray_background_button {
  @include gray_background_button;
}
