@import 'mixins/layout_header';

.layout_header {
  position: fixed;
  z-index: 5000;
  width: 100%;
  background: $white;
  transition-property: background-color, border-bottom-color, color;
  transition-timing-function: ease;
  transition-duration: 0.3s;
  border-bottom: 1px solid #d5d5d5;

  &.repainted {
    background: $white;
  }

  /// Top Bar Temporarily closed
  @import 'header/temporary_closed';

  /// Top Bar Layout
  @import 'header/top_bar_layout';

  /// Top Bar Logo
  @import 'header/top_bar_logo';

  /// Top Bar TripAdvisor
  @import 'header/top_bar_tripadvisor';

  /// Top Bar City menu (select city) (removed on 12.12.2018)

  /// Top Bar Pages navigation (rooms, location, schedule)
  @import 'header/top_bar_pages_nav';

  /// Top Bar Sign in
  @import 'header/top_bar_signin';

  /// Top Bar Shopping Cart
  @import 'header/top_bar_shopping_cart';

  /// Top Bar Open main menu button
  @import 'header/top_bar_open_main_menu_button';

  @import 'header/callout';
}
