/// Copyright

.copyright_and_info {
  width: 100%;
  margin-top: rem-calc(30);
  text-align: center;
  color: #858585;
  font: {
    size: rem-calc(14);
  }

  @include breakpoint(large up) {
    width: 50%;
    margin-top: 0;
    text-align: left;
  }
}

.copyright,
.policy_and_terms {
  @include breakpoint(medium only) {
    display: inline;
  }
}

// .policy_and_terms,
// .powered_by {
//   @include breakpoint(large up) {
//     display: inline;
//   }
// }

// .policy_and_terms {
//   a {
//     white-space: nowrap;
//   }
// }

// .powered_by {
//   a {
//     color: $white;

//     img {
//       width: rem-calc(90);
//     }
//   }
// }
