/// Top Bar Open main menu button
.top_bar {
  div.open_menu_button {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-content: stretch;
    min-width: rem-calc(38);
    min-height: rem-calc(38);

    button.open_menu_button {
      display: block;
      color: #000;
      min-width: rem-calc(40);

      font: {
        size: rem-calc(14);
      }

      text-transform: uppercase;
      cursor: pointer;
      outline: none;

      .icon {
        height: rem-calc(15);
        width: rem-calc(15);
        margin-top: rem-calc(-2);
      }
    }

    .title {
      @include element-invisible;
    }
  }
}

@include breakpoint(xxlarge up) {
  .top_bar {
    div.open_menu_button {
      margin-left: rem-calc(15);

      button.open_menu_button {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        width: auto;
        padding-top: rem-calc(2);
      }

      .icon {
        margin-left: rem-calc(10);
      }

      .title {
        @include element-invisible-off;
      }
    }
  }
}
